import { DataProps, DataSection } from 'packages/formidable';
import React, { FC, memo, SyntheticEvent } from 'react';

import ViewModal from '~/components/View/Modal';

import action from './datas/action.json';
import evaluation from './datas/evaluation.json';
// import question from './datas/question.json';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  name: string;
  params?: { [key: string]: any };
  title: string;
}

const Modal: FC<ModalProps> = ({
  closeOnClick,
  formName,
  name,
  params,
  title,
}) => (
  <ViewModal
    buttons={{
      right: [{ label: 'Fermer', onClick: closeOnClick }],
    }}
    closeOnClick={closeOnClick}
    title={title}
  >
    <DataSection
      datas={
        [
          {
            cols: ['none', 2],
            componentType: 'grid',
            datas: [
              {
                componentType: 'input',
                label: 'Identifiant',
                name: 'id',
                type: 'text',
              },
              {
                componentType: 'select',
                label: 'Type',
                name: 'kind',
                options: [
                  { label: 'Message', value: 'message' },
                  { label: 'Question', value: 'question' },
                  { label: 'Action', value: 'action' },
                  {
                    label: 'Évaluation',
                    value: 'evaluation',
                  },
                ],
              },
            ],
          },
          {
            componentType: 'input',
            description: 'Obligatoire ?',
            labelShow: false,
            name: 'required',
            type: 'checkbox',
          },
          {
            componentType: 'input',
            description: 'Est-ce que cette interaction est le message de fin ?',
            labelShow: false,
            name: 'isFinal',
            type: 'checkbox',
          },
          {
            componentType: 'box',
            datas: [
              {
                componentType: 'array',
                display: 'ghost',
                label: 'Message',
                name: 'contents',
              },
            ],
          },
          {
            componentType: 'textarea',
            label: 'Description',
            name: 'description',
          },
          {
            componentType: 'condition',
            datas: action as DataProps[],
            test: {
              field: `${name}.kind`,
              operator: '==',
              value: 'action',
            },
          },
          // {
          //   componentType: 'condition',
          //   datas: question as DataProps[],
          //   test: {
          //     field: `${name}.kind`,
          //     operator: '==',
          //     value: 'question',
          //   },
          // },
          {
            componentType: 'condition',
            datas: evaluation as DataProps[],
            test: {
              field: `${name}.kind`,
              operator: '==',
              value: 'evaluation',
            },
          },
        ] as any[]
      }
      formName={formName}
      name={name}
      params={{
        ...params,
        name,
      }}
    />
  </ViewModal>
);

const propsAreEqual = (prevProps: ModalProps, nextProps: ModalProps): boolean =>
  prevProps.formName === nextProps.formName &&
  prevProps.name === nextProps.name;

export default memo(Modal, propsAreEqual);
