import { DataField, DataFieldProps } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconRedo from '~/icons/Redo';

export type DataEspacesCodeProps = Omit<DataFieldProps, 'componentType'>;

const DataEspacesCode: FC<DataEspacesCodeProps> = ({
  fieldProps,
  id,
  name,
  params,
  templateProps,
  wrapperProps,
  ...props
}) => {
  const handleGenerateOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <DataField
      {...props}
      componentType="input"
      customAction={
        <Button
          className="rounded-l-none"
          color="neutral"
          iconClassName="h-[16px] w-[16px]"
          iconLeft={IconRedo}
          onClick={handleGenerateOnClick}
          variant="field"
        />
      }
      fieldProps={{
        ...props.fieldProps,
        className: 'rounded-r-none border-r-0',
      }}
      name={name}
    />
  );
};

export default DataEspacesCode;
